<template>
    <div class="flex flex-col mx-10 space-y-8">

        <div @click="back"
             class="flex flex-row space-x-6 cursor-pointer border border-transparent hover:border-secondary rounded max-w-fit p-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
            </svg>
            <span>Back to Sectors</span>
        </div>

        <div class="flex flex-row justify-between">
            <span class="font-semibold text-xl py-2">
               S/NO: <span
                class="mr-2 ml-1">{{ journey_detail.journey ? journey_detail.journey.serial_number : '' }}</span>
                - <span class="ml-2 uppercase">{{ journey_detail.checkpoint_type }} JOURNEY</span>
                - <span
                :class="{'text-blue-500': journey_detail.status === 'Closed', 'text-orange-300' : journey_detail.status === 'Open'}"
                class="ml-2 uppercase">{{ journey_detail.status }}</span>
            </span>
            <el-button
                :disabled="journey_checkpoints.data.some(obj => obj.status === 'Open') || journey_detail.status === 'Closed'"
                @click="closeJourney()"
                type="success"
                plain>
                <span class="mr-2">Mark Sector as Closed</span>
                <el-icon>
                    <Check/>
                </el-icon>
            </el-button>
        </div>

        <div class="flex flex-row justify-between">
            <el-button
                :disabled="journey_checkpoints.data.some(obj => obj.status === 'Open') || journey_detail.status === 'Closed'"
                size="large"
                @click="openModal"
                class="bg-secondary text-white space-x-2 border border-secondary hover:bg-primary-600 hover:text-white">
                <el-icon class="mr-2">
                    <Plus/>
                </el-icon>
                Add new Checkpoint
            </el-button>
        </div>

        <div class="flex flex-col border rounded shadow p-6 bg-white">
            <span class="flex flex-row w-full font-semibold text-xl py-2 border-b">
               Checkpoints
            </span>

            <div>
                <div class="block w-full overflow-x-auto">
                    <!--Table-->
                    <div>
                        <el-table :data="journey_checkpoints.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="company_branch.name" label="LOCATION"/>
                            <el-table-column prop="planned_arrival" label="PLANNED ARRIVAL"/>
                            <el-table-column prop="actual_arrival" label="ACTUAL ARRIVAL"/>
                            <el-table-column prop="departure_time" label="DEPARTURE"/>
                            <el-table-column prop="delay_in_arrival" label="DELAY IN ARRIVAL(HRS & MIN)"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column width="200" label="COMPLIANT STATUS">
                                <template #default="scope">
                                    <el-checkbox onclick="return false;" :checked="scope.row.is_tipping_done">Tipping
                                        Done
                                    </el-checkbox>
                                    <el-checkbox onclick="return false;" :checked="scope.row.is_breathalyser_achieved">
                                        Breathalyser Achieved
                                    </el-checkbox>
                                    <el-checkbox onclick="return false;" :checked="scope.row.is_photo_taken">Photo Taken
                                    </el-checkbox>
                                    <el-checkbox onclick="return false;" :checked="scope.row.is_video_captured">Video
                                        Captured
                                    </el-checkbox>
                                </template>
                            </el-table-column>
                            <el-table-column prop="created_at" label="DATE FILLED"/>
                            <el-table-column prop="created_by.full_name" label="FILLED BY"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-button size="default"
                                               @click="updateCheckpoint(scope.row)" circle>
                                        <el-icon>
                                            <DArrowRight/>
                                        </el-icon>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="journey_checkpoints.current_page"
                            :page-size="journey_checkpoints.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="journey_checkpoints.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </div>
            </div>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCheckpointDialogVisible"
                title="Checkpoints"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div :class="{'cursor-not-allowed opacity-50': this.form.id && this.form.status === 'Open'}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Checkpoint Location
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select @change="selectJourneyHop" filterable :disabled="this.form.id"
                                               style="width: 100%" v-model="form.company_branch_id"
                                               placeholder="Select location" size="default">
                                        <el-option
                                            v-for="item in company_branches"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.company_branch_id  && this.has_errors">
                                    {{ errors.data.web_errors.company_branch_id[0] }}
                                </span>
                        </div>
                        <div :class="{'cursor-not-allowed opacity-50': this.form.id && this.form.status === 'Open'}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Planned Arrival Date
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker
                                        :disabled="this.form.id || disable_planned_arrival"
                                        style="width: 100%"
                                        v-model="form.planned_arrival_date"
                                        type="date"
                                        placeholder="Pick the date"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.planned_arrival_date  && this.has_errors">
                                    {{ errors.data.web_errors.planned_arrival_date[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': this.form.id && this.form.status === 'Open'}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Planned Arrival Time
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-time-picker
                                        :disabled="this.form.id || disable_planned_arrival"
                                        format="HH:mm"
                                        arrow-control
                                        style="width: 100%"
                                        v-model="form.planned_arrival_time"
                                        placeholder="Pick the time"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.planned_arrival_time  && this.has_errors">
                                    {{ errors.data.web_errors.planned_arrival_time[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Actual Arrival Date
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker
                                        :disabled="!this.form.id || this.form.status === 'Closed'"
                                        style="width: 100%"
                                        v-model="form.actual_arrival_date"
                                        type="date"
                                        placeholder="Pick the date"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.actual_arrival_date  && this.has_errors">
                                    {{ errors.data.web_errors.actual_arrival_date[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Actual Arrival Time
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-time-picker
                                        :disabled="!this.form.id || this.form.status === 'Closed'"
                                        format="HH:mm"
                                        arrow-control
                                        style="width: 100%"
                                        v-model="form.actual_arrival_time"
                                        placeholder="Pick the time"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.actual_arrival_time  && this.has_errors">
                                    {{ errors.data.web_errors.actual_arrival_time[0] }}
                                </span>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.actual_time_taken  && this.has_errors">
                                    {{ errors.data.web_errors.actual_time_taken[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Date
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker
                                        :disabled="!this.form.id || this.form.status === 'Closed'"
                                        style="width: 100%"
                                        type="date"
                                        v-model="form.departure_date"
                                        placeholder="Pick the date"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.web_errors.departure_date  && this.has_errors">
                                    {{ errors.data.web_errors.departure_date[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Time
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-time-picker
                                        arrow-control
                                        format="HH:mm"
                                        :disabled="!this.form.id || this.form.status === 'Closed'"
                                        style="width: 100%"
                                        v-model="form.departure_time_time"
                                        placeholder="Pick the time"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.departure_time_time  && this.has_errors">
                                    {{ errors.data.web_errors.departure_time_time[0] }}
                                </span>
                        </div>

                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Compliance Status</p>
                            <div class="flex flex-col">
                                <span class="mr-2">Is Driver Compliant?</span>
                                <div>
                                    <el-radio v-model="form.is_compliant" :label="1">Yes
                                    </el-radio>
                                    <el-radio v-model="form.is_compliant" :label="0">No</el-radio>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.web_errors.is_compliant  && this.has_errors">
                                    {{ errors.data.web_errors.is_compliant[0] }}
                                </span>
                        </div>

                        <div v-if="form.is_compliant !== 1" :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Non Compliance Reasons
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select filterable
                                               style="width: 100%" v-model="form.non_compliance_reason_id"
                                               placeholder="Select non compliance reason" size="default">
                                        <el-option
                                            v-for="item in non_compliance_reasons"
                                            :key="item.id"
                                            :label="item.reason"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500"
                                  v-if="errors.data.web_errors.non_compliance_reason_id  && this.has_errors">
                                    {{ errors.data.web_errors.non_compliance_reason_id[0] }}
                                </span>
                        </div>
                        <div :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Is this the last checkpoint?</p>
                            <div class="flex flex-col">
                                <el-checkbox v-model="form.is_last_checkpoint">Last Checkpoint
                                </el-checkbox>
                            </div>
                        </div>
                        <div v-if="!form.is_last_checkpoint" :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Activity Checks</p>
                            <div class="flex flex-col">
                                <el-checkbox
                                    v-if="journey_detail.checkpoint_type !== 'Forward' || journey_detail.truck_status === 'empty'"
                                    :disabled="!this.form.id || this.form.status === 'Closed'"
                                    v-model="form.is_tipping_done">Tipping
                                    Done
                                </el-checkbox>
                                <el-checkbox :disabled="!this.form.id || this.form.status === 'Closed'"
                                             v-model="form.is_breathalyser_achieved">
                                    Breathalyser Achieved
                                </el-checkbox>
                                <el-checkbox :disabled="!this.form.id || this.form.status === 'Closed'"
                                             v-model="form.is_photo_taken">Photo Taken
                                </el-checkbox>
                                <el-checkbox :disabled="!this.form.id || this.form.status === 'Closed'"
                                             v-model="form.is_video_captured">Video
                                    Captured
                                </el-checkbox>
                            </div>
                        </div>
                        <div v-if="skip_reason === true" :class="{'cursor-not-allowed opacity-50': !this.form.id}"
                             class="flex flex-col items-start justify-start mb-4 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Skip Reasons
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select filterable
                                               style="width: 100%" v-model="form.skip_reason_id"
                                               placeholder="Select skip reason" size="default">
                                        <el-option
                                            v-for="item in skip_reasons"
                                            :key="item.id"
                                            :label="item.reason"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.web_errors.skip_reason_id  && this.has_errors">
                                    {{ errors.data.web_errors.skip_reason_id[0] }}
                                </span>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button :disabled="this.form.status === 'Closed' || working_hours_disable"
                                   class="bg-sky-600 text-white"
                                   @click="addCheckpoint">
                            {{ this.form.id ? 'Close Checkpoint' : 'Add Checkpoint' }}
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addCheckpointDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {Check, DArrowRight, Plus} from "@element-plus/icons-vue";
import {mapActions, mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
    data() {
        return {
            value1: null,
            search: '',
            windowWidth: window.innerWidth,
            addCheckpointDialogVisible: false,
            form: {},
            journey_detail_form: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {},
            skip_reason: false,
            filteredHop: {},
            working_hours_disable: false,
            disable_planned_arrival: false
        };
    },
    components: {
        Check,
        Plus,
        DArrowRight
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getJourneyDetail(this.$route.params.id).then(()=>{
            this.getJourneyCheckpoints({
                journey_detail_id: this.$route.params.id,
                ...this.filters
            }).then(()=>{
                this.getCompanyBranchesList({
                    journey:true,
                    id: this.user.user_company.company_id,
                    last_checkpoint:this.last_checkpoint ? this.last_checkpoint.company_branch_id : null,
                    sector:this.journey_detail.checkpoint_type,
                })
            })
        })
        this.getSkipReasons({})
        this.getNonComplianceReasons({})
        this.getJourneyHops({
            id: this.user.user_company.company_id
        })
        this.getCompanyConfigs({
            id: this.user.user_company.company_id
        })
    },
    computed: {
        disabledSeconds() {
            return this.makeRange(0, 16).concat(this.makeRange(19, 23))
        },
        ...mapGetters({
            'errors': "journeys/errors",
            'has_errors': 'journeys/has_errors',
            company_branches: 'company_branches/company_branches',
            journey_checkpoints: 'journeys/journey_checkpoints',
            journey_detail: 'journeys/journey_detail',
            skip_reasons: 'journeys/skip_reasons',
            non_compliance_reasons: 'journeys/non_compliance_reasons',
            journey_hops: 'journeys/journey_hops',
            last_checkpoint: 'journeys/last_checkpoint',
            company_configs: 'configs/configs'
        }),
    },
    methods: {
        makeRange(start, end) {
            const result = [];
            for (let i = start; i <= end; i++) {
                result.push(i);
            }
            return result;
        },
        ...mapActions({
            getCompanyBranchesList: 'company_branches/getCompanyBranches',
            getJourneyCheckpoints: 'journeys/getJourneyCheckpoints',
            createJourneyCheckpoints: 'journeys/createJourneyCheckpoints',
            editJourneyCheckpoint: 'journeys/editJourneyCheckpoint',
            getJourneyDetail: 'journeys/getJourneyDetail',
            editJourneyDetail: 'journeys/editJourneyDetail',
            getNonComplianceReasons: 'journeys/getNonComplianceReasons',
            getSkipReasons: 'journeys/getSkipReasons',
            getJourneyHops: 'journeys/getJourneyHops',
            getCompanyConfigs: 'configs/getConfigs',
            closeJourneyDetail: 'journeys/closeJourneyDetail'
        }),
        openModal() {
            this.form = {}
            this.addCheckpointDialogVisible = true
            this.getCompanyBranchesList({
                journey:true,
                id: this.user.user_company.company_id,
                last_checkpoint:this.last_checkpoint ? this.last_checkpoint.company_branch_id : null,
                sector:this.journey_detail.checkpoint_type,
            })
        },
        addCheckpoint() {
            console.log(this.form)
            this.form.journey_detail_id = this.$route.params.id
            if (this.form.id) {
                this.editJourneyCheckpoint(this.form).then(() => {
                    if (!this.form.actual_arrival_time && !this.form.actual_arrival_date) {
                        this.skip_reason = true
                    }
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCheckpointDialogVisible = false
                        if (this.form.is_last_checkpoint) {
                            this.closeJourney()
                        }
                    }
                })
            } else {
                this.createJourneyCheckpoints(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCheckpointDialogVisible = false
                    }
                });
            }
        },
        updateCheckpoint(item) {
            this.addCheckpointDialogVisible = true
            this.form = Object.assign({}, this.form, item);
            this.form.planned_arrival_time = this.form.planned_arrival
            this.form.actual_arrival_time = this.form.actual_arrival
            this.form.departure_time_time = this.form.departure_time
        },
        closeJourney() {
            this.journey_detail_form.status = 'Closed'
            this.journey_detail_form.id = this.$route.params.id
            this.closeJourneyDetail(this.journey_detail_form).then(() => {
                this.getJourneyDetail(this.$route.params.id)
                if (!this.has_errors) {
                    this.journey_detail_form = {}
                }

            })
        },
        back() {
            this.$router.push('/journey/' + this.journey_detail.journey.serial_number)
        },
        selectJourneyHop(company_branch_id) {
            let loaded = false;
            this.disable_planned_arrival = false
            if (this.journey_detail.checkpoint_type === 'Forward') {
                loaded = true;
            } else loaded = this.journey_detail.checkpoint_type === 'Return' && this.journey_detail.truck_status === 'loaded';
            this.form.planned_arrival_date = ''
            this.form.planned_arrival_time = ''
            this.working_hours_disable = false
            if (this.last_checkpoint) {
                this.filteredHop = this.journey_hops.find((journey_hop) => {
                    return journey_hop.branch_from_id === this.last_checkpoint.company_branch_id
                        && journey_hop.branch_to_id === company_branch_id
                        && journey_hop.is_loaded === loaded;
                });
                if (this.filteredHop) {
                    if (this.last_checkpoint.departure_date) {
                        const departureTime = this.parseDateTime(this.last_checkpoint.departure_time);
                        const plannedArrival = new Date(departureTime);
                        plannedArrival.setHours(departureTime.getHours() + this.filteredHop.duration_in_hrs);
                        this.form.planned_arrival_date = new Date(plannedArrival).toDateString()
                        this.form.planned_arrival_time = new Date(plannedArrival).toLocaleString()

                        if (plannedArrival) {
                            this.disable_planned_arrival = true
                        }else{
                            this.disable_planned_arrival = false
                        }

                        if (this.company_configs.length) {
                            if (new Date(plannedArrival).toTimeString() > this.company_configs[0].working_hours_end_time) {
                                this.working_hours_disable = true
                                console.log('date is after working time')
                                console.log(new Date(plannedArrival).toTimeString(), this.company_configs[0].working_hours_end_time)
                                ElMessage({
                                    message: 'You will arrive at ' + new Date(plannedArrival).toTimeString() + ' which is later than the working hours. Please add a different checkpoint location',
                                    type: 'warning',
                                    duration: 10000
                                })
                            } else {
                                this.working_hours_disable = false
                            }
                        }
                    }
                }
            }
        },
        parseDateTime(dateTimeString) {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            const [hours, minutes, seconds] = timePart.split(':').map(Number);
            return new Date(year, month - 1, day, hours, minutes, seconds);
        }
    }
}
</script>
