<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Journeys
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addJourneyDialogVisible = true">
                            Add Journey
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--    Filters and Search-->
        <div class="flex flex-col py-6 px-6 space-y-2 md:space-x-4 space-x-0">

            <div class="flex lg:flex-row flex-col justify-between md:items-start ">
                <div class="block">
                    <span class="text-black mr-2">Date Range</span>
                    <el-date-picker
                        v-model="date_range"
                        type="datetimerange"
                        :shortcuts="shortcuts"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                    />
                </div>
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Journeys ...">
                    </div>
                </form>
            </div>

        </div>

        <!-- Add/Edit dialog-->
        <el-dialog
            v-model="addJourneyDialogVisible"
            title="Add Journey"
            :width="windowWidth > 768 ? '50%' : '90%'">
            <div class="flex flex-col gap-y-2">
                <form class="px-4">
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Truck No<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-center justify-between w-full mb-1 space-x-2">
                            <div class="flex flex-row rounded-md w-1/2">
                                <el-select
                                    filterable
                                    style="width: 100%" v-model="form.truck_id"
                                    placeholder="Select a vehicle"
                                    size="large">
                                    <el-option
                                        v-for="item in trucks"
                                        :key="item.id"
                                        :label="item.reg_number"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                            <span>OR</span>
                            <div class="flex flex-row justify-between space-x-2 w-1/2">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="truck.reg_number"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="truck number"
                                        name="form.name" required />
                                </div>
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="truck.model"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="model"
                                        name="form.name" />
                                </div>
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="truck.inspection_company"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="inspection company"
                                        name="form.name" />
                                </div>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.truck_id  && this.has_errors">
                                    {{ errors.data.web_errors.truck_id[0] }}
                                </span>
                    </div>

                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Trailer Registration<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-center justify-between w-full mb-1 space-x-2">
                            <div class="flex flex-row rounded-md w-1/2">
                                <el-select
                                    filterable
                                    style="width: 100%" v-model="form.trailer_id"
                                    placeholder="Select a vehicle"
                                    size="large">
                                    <el-option
                                        v-for="item in trailers"
                                        :key="item.id"
                                        :label="item.reg_number"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                            <span>OR</span>
                            <div class="flex flex-row justify-between space-x-2 w-1/2">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="trailer.reg_number"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="trailer reg"
                                        name="form.name" required />
                                </div>
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="trailer.model"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="model"
                                        name="form.name" />
                                </div>
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        disabled
                                        v-model="trailer.inspection_company"
                                        class="cursor-not-allowed py-1.5 text-slate-400 bg-gray-200 rounded text-sm border border-gray-300 outline-none w-full"
                                        type="text"
                                        placeholder="inspection company"
                                        name="form.name" />
                                </div>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.trailer_id  && this.has_errors">
                                    {{ errors.data.web_errors.trailer_id[0] }}
                                </span>
                    </div>

                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Driver Name<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-center justify-between w-full mb-1 space-x-2">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    filterable
                                    style="width: 100%" v-model="form.drivers_name"
                                    placeholder="Select a driver"
                                    size="large">
                                    <el-option
                                        v-for="item in users"
                                        :key="item.id"
                                        :label="item.full_name"
                                        :value="item.full_name"
                                    />
                                </el-select>
                            </div>
                            <span>OR</span>
                            <div class="flex flex-row rounded-md w-full">
                                <input
                                    v-model="form.drivers_name"
                                    class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:ring-secondary focus:border-secondary w-full"
                                    type="text"
                                    placeholder="driver's name"
                                    name="form.name" required/>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.drivers_name && this.has_errors">
                                    {{ errors.data.web_errors.drivers_name[0] }}
                                </span>
                    </div>
                </form>
                <div class="dialog-footer flex flex-row w-full justify-end px-4">
                    <el-button class="bg-red-700 text-white" type="danger"
                               @click="addJourneyDialogVisible = false">
                        Cancel
                    </el-button>
                    <el-button class="bg-sky-600 text-white" @click="addJourney">
                        Save Journey
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <div class="block w-full overflow-x-auto">

            <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
                <div class="flex flex-row justify-end pt-6">
                    <el-button @click="exportJourneys" type="success" plain size="large">
                        <span class="mr-3">Download Journeys Excel</span>
                        <el-icon>
                            <Download/>
                        </el-icon>
                    </el-button>
                </div>
                <el-tab-pane name="All">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            All
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="journeys.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="serial_number" label="SERIAL NUMBER"/>
                            <el-table-column prop="truck.reg_number" label="TRUCK NUMBER"/>
                            <el-table-column prop="trailer.reg_number" label="TRAILER"/>
                            <el-table-column prop="drivers_name" label="DRIVER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="is_compliant" label="IS_COMPLIANT"/>
                            <el-table-column prop="summary" label="SUMMARY"/>
                            <el-table-column prop="date_closed" label="DATE CLOSED"/>
                            <el-table-column prop="created_at" label="DATE CREATED"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="viewJourneyDetails(scope.row)">View Sectors
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="downloadReport(scope.row)">Download Report
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="journeys.current_page"
                            :page-size="journeys.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="journeys.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane name="Open">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            Open
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="journeys.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="serial_number" label="SERIAL NUMBER"/>
                            <el-table-column prop="truck.reg_number" label="TRUCK NUMBER"/>
                            <el-table-column prop="trailer.reg_number" label="TRAILER"/>
                            <el-table-column prop="drivers_name" label="DRIVER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="is_compliant" label="IS_COMPLIANT"/>
                            <el-table-column prop="summary" label="SUMMARY"/>
                            <el-table-column prop="date_closed" label="DATE CLOSED"/>
                            <el-table-column prop="created_at" label="DATE CREATED"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="viewJourneyDetails(scope.row)">View Sectors
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="downloadReport(scope.row)">Download Report
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="journeys.current_page"
                            :page-size="journeys.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="journeys.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane name="Closed">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            Closed
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="journeys.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="serial_number" label="SERIAL NUMBER"/>
                            <el-table-column prop="truck.reg_number" label="TRUCK NUMBER"/>
                            <el-table-column prop="trailer.reg_number" label="TRAILER"/>
                            <el-table-column prop="drivers_name" label="DRIVER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="is_compliant" label="IS_COMPLIANT"/>
                            <el-table-column prop="summary" label="SUMMARY"/>
                            <el-table-column prop="date_closed" label="DATE CLOSED"/>
                            <el-table-column prop="created_at" label="DATE CREATED"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="viewJourneyDetails(scope.row)">View Sectors
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="downloadReport(scope.row)">Download Report
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="journeys.current_page"
                            :page-size="journeys.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="journeys.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {ArrowDown, Download} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";
import moment from "moment/moment";

export default {
    components: {Download, ArrowDown},
    data() {
        return {
            search: '',
            windowWidth: window.innerWidth,
            addJourneyDialogVisible: false,
            form: {},
            user: {},
            inspection: {},
            company_vehicle: {},
            truck: {
                vehicle_type_id: 2
            },
            trailer: {
                vehicle_type_id: 3
            },
            filters: {
                paginate: true,
                per_page: 10
            },
            create_journey_disabled:false,
            activeTab : 'All',
            date_range: [],
            shortcuts: [
                {
                    text: 'Today',
                    value: () => {
                        let start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of today (midnight)

                        let end = new Date();
                        end.setHours(23, 59, 59, 999); // End of today
                        console.log(start,end)
                        return [ start, end ];
                    },
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        let start = new Date();
                        start.setDate(start.getDate() - 1);
                        start.setHours(0, 0, 0, 0); // Start of yesterday (midnight)

                        let end = new Date();
                        end.setDate(end.getDate() - 1);
                        end.setHours(23, 59, 59, 999); // End of yesterday
                        console.log(start,end)
                        return [ start, end ];
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = (dayOfWeek + 6) % 7;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]

                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getJourneysList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanyVehicles({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getUsers({})
        this.getVehicleTypes(this.filters)
    },
    computed: {
        ...mapGetters({
            errors: "journeys/errors",
            has_errors: 'journeys/has_errors',
            vehicle_errors:'company_vehicles/errors',
            vehicle_has_errors:'company_vehicles/has_errors',
            journeys: 'journeys/journeys',
            companies: 'companies/companies',
            company_vehicles: 'company_vehicles/company_vehicles',
            vehicle_types: 'vehicle_types/vehicle_types',
            users: 'users/users',
            truck_has_open_journeys:'journeys/truck_has_open_journeys'
        }),
        trucks() {
            return this.company_vehicles.filter((vehicle) => {
                return vehicle.vehicle_type_id === 2
            })
        },
        trailers() {
            return this.company_vehicles.filter((vehicle) => {
                return vehicle.vehicle_type_id === 3
            })
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getJourneysList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        search: function (val) {
            this.getJourneysList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
                search: val
            });
        },
        'date_range': function (val) {
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getJourneysList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
            })
        },

    },
    methods: {
        ...mapActions({
            getJourneysList: 'journeys/getJourneys',
            createJourneys: 'journeys/createJourneys',
            editJourney: 'journeys/editJourney',
            removeJourney: 'journeys/deleteJourney',
            getCompanyVehicles: 'company_vehicles/getCompanyVehicles',
            getUsers: 'users/getUsers',
            downloadJourneyReport: 'journeys/downloadJourneyReport',
            getVehicleTypes: 'vehicle_types/getVehicleTypes',
            createCompanyVehicles: 'company_vehicles/createCompanyVehicles',
            downloadJourneys:'journeys/exportJourneys',
            getTruckOpenJourneys:'journeys/getTruckOpenJourneys'

        }),
        checkIfVehicleHasOpenJourney(vehicle_id){
            this.create_journey_disabled = false
            this.getTruckOpenJourneys({
                vehicle_id: vehicle_id
            }).then(()=>{
                console.log(this.truck_has_open_journeys)
                this.create_journey_disabled = !!this.truck_has_open_journeys;
            })

        },
        handleTabClick(tab,event){
            console.log(event,tab)
            if(tab.props.name === 'All'){
                this.filters.status = ''
            }else if(tab.props.name === 'Closed'){
                this.filters.status = 'Closed'
            }else{
                this.filters.status = 'Open'
            }
            localStorage.setItem('activeTab', this.filters.status);
            this.getJourneysList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            })
        },
        exportJourneys(){
            this.filters.status = localStorage.getItem('activeTab')
            this.downloadJourneys({
                ...this.filters
            });
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getJourneysList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getJourneysList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleDelete(item) {
            this.deleteVehicleInspection({id: item.id}).then(() => {
                this.getFinalInspections({id: item.vehicle_id});
                this.getVehicleInspections({paginate: true, id: item.vehicle_id})
            })
        },
        async addJourney() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            this.trailer.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            this.truck.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.truck.reg_number) {
                await this.createCompanyVehicles(this.truck).then((response) => {
                    if (this.vehicle_has_errors) {
                        ElMessage({
                            message: 'Truck registration already exists',
                            type: "error",
                        })
                    }
                    this.form.truck_id = response.data.id
                })
            }
            if (this.trailer.reg_number) {
                await this.createCompanyVehicles(this.trailer).then((response) => {
                    if (this.vehicle_has_errors) {
                        ElMessage({
                            message: 'Trailer registration already exists',
                            type: 'error',
                        })
                    }
                    this.form.trailer_id = response.data.id
                })
            }
            setTimeout(() => {
                if (this.form.truck_id && this.form.trailer_id) {
                    this.createJourneys(this.form).then(() => {
                        if (!this.has_errors) {
                            this.form = {}
                            this.addJourneyDialogVisible = false
                        }
                    });
                } else {
                    ElMessage({
                        message: 'truck and trailer registrations are required',
                        type: 'error',
                    })
                }
            }, 500)
            // console.log(this.form)


        },
        viewJourneyDetails(item) {
            this.$router.push('/journey/' + item.serial_number)
        },
        updateJourney(item) {
            this.addJourneyDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteJourney(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeJourney({
                    id: item.id
                })
            }
        },
        downloadReport(item) {
            ElMessage({
                message: 'Downloading report, Please wait...',
                type: 'success',
                duration: 10000
            })
            this.downloadJourneyReport({id: item.id, serial_number:item.serial_number}).then(() => {
                ElMessage({
                    message: 'Report downloaded',
                    type: 'success',
                    duration: 10000
                })
            })
        }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
