import api from "../api";
import {ElMessage} from 'element-plus'
import router from "@/router";

export default {
    namespaced: true,
    state: {
        journeys: {data: [], current_page: 1, total: 0, per_page: 10},
        journey_details: {data: [], current_page: 1, total: 0, per_page: 10},
        journey_checkpoints: {data: [], current_page: 1, total: 0, per_page: 10},
        last_checkpoint: {},
        journey: {},
        journey_detail: {},
        truck_has_open_journeys: {},
        journey_checkpoint: {},
        skip_reasons: {data: [], current_page: 1, total: 0, per_page: 10},
        non_compliance_reasons: {data: [], current_page: 1, total: 0, per_page: 10},
        journey_hops: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                web_errors: {
                    // journeys
                    truck_number: [],
                    trailer_registration: [],
                    drivers_name: [],
                    date_closed: [],
                    serial_number: [],
                    is_compliant: [],
                    summary: [],
                    company_id: [],
                    truck_id: [],
                    trailer_id: [],

                    //     journey details
                    journey_id: [],
                    checkpoint_type: [],
                    delivery_number: [],
                    date_of_delivery: [],
                    departure_date: [],
                    departure_time: [],
                    truck_status: [],
                    status: [],
                    created_by_id: [],
                    product_id: [],
                    destination_id: [],
                    location_of_loading_id: [],
                    port_of_loading_id: [],
                    expected_arrival_date: [],
                    customer_id: [],
                    arrival_date: [],
                    arrival_time: [],
                    port_of_discharge_id: [],
                    comments: [],
                    issue_one: [],
                    date: [],
                    trucking_status_compliance: [],
                    alcoblow_test_compliance: [],
                    loading_date: [],
                    loading_time: [],


                    //     Checkpoints
                    journey_detail_id: [],
                    company_branch_id: [],
                    planned_arrival: [],
                    planned_arrival_time: [],
                    planned_arrival_date: [],
                    actual_arrival: [],
                    actual_arrival_date: [],
                    actual_arrival_time: [],
                    departure_time_time: [],
                    delay_in_arrival: [],
                    is_tipping_done: [],
                    is_breathalyser_achieved: [],
                    is_photo_taken: [],
                    is_video_captured: [],
                    skip_reason_id: [],
                    non_compliance_reason_id: [],
                    actual_time_taken:[],
                    expected_time_of_arrival:[],
                    expected_date_of_arrival:[]
                }
            }
        },
        has_errors: false,
    },
    getters: {
        truck_has_open_journeys: state => state.truck_has_open_journeys,
        last_checkpoint: state => state.last_checkpoint,
        journey_hops: state => state.journey_hops,
        skip_reasons: state => state.skip_reasons,
        non_compliance_reasons: state => state.non_compliance_reasons,
        journey: state => state.journey,
        journeys: state => state.journeys,
        journey_checkpoint: state => state.journey_checkpoint,
        journey_checkpoints: state => state.journey_checkpoints,
        journey_detail: state => state.journey_detail,
        journey_details: state => state.journey_details,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setTruckHasOpenJourneys(state, data) {
            state.truck_has_open_journeys = data;
        },
        setLastCheckpoint(state, data) {
            state.last_checkpoint = data;
        },
        setJourneyHops(state, data) {
            state.journey_hops = data;
        },
        setSkipReasons(state, data) {
            state.skip_reasons = data;
        },
        setNonComplianceReasons(state, data) {
            state.non_compliance_reasons = data;
        },
        setJourneyCheckpoints(state, data) {
            state.journey_checkpoints = data;
        },
        setJourneyCheckpoint(state, data) {
            state.journey_detail = data;
        },
        setJourneyDetails(state, data) {
            state.journey_details = data;
        },
        setJourneyDetail(state, data) {
            state.journey_detail = data;
        },
        setJourney(state, data) {
            state.journey = data;
        },
        setJourneys(state, data) {
            state.journeys = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        web_errors: {
                            truck_number: [],
                            trailer_registration: [],
                            drivers_name: [],
                            date_closed: [],
                            serial_number: [],
                            is_compliant: [],
                            summary: [],
                            company_id: [],
                            truck_id: [],
                            trailer_id: [],


                            //     journey details
                            journey_id: [],
                            checkpoint_type: [],
                            delivery_number: [],
                            date_of_delivery: [],
                            departure_date: [],
                            departure_time: [],
                            truck_status: [],
                            status: [],
                            created_by_id: [],
                            product_id: [],
                            destination_id: [],
                            location_of_loading_id: [],
                            port_of_loading_id: [],
                            expected_arrival_date: [],
                            customer_id: [],
                            arrival_date: [],
                            arrival_time: [],
                            port_of_discharge_id: [],
                            comments: [],
                            issue_one: [],
                            date: [],
                            trucking_status_compliance: [],
                            alcoblow_test_compliance: [],
                            loading_date: [],
                            loading_time: [],


                            //     Checkpoints
                            journey_detail_id: [],
                            company_branch_id: [],
                            planned_arrival: [],
                            actual_arrival: [],
                            delay_in_arrival: [],
                            is_tipping_done: [],
                            is_breathalyser_achieved: [],
                            is_photo_taken: [],
                            is_video_captured: [],
                            skip_reason_id: [],
                            non_compliance_reason_id: [],
                            actual_time_taken:[],
                            expected_time_of_arrival:[],
                            expected_date_of_arrival:[]
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getJourneyDetail({commit}, id) {
            try {
                const response = await api.getJourneyDetail(id);
                commit('setJourneyDetail', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getJourney({commit}, id) {
            try {
                const response = await api.getJourney(id);
                commit('setJourney', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getJourneys({commit}, filters) {
            try {
                const response = await api.getJourneys(filters);
                commit('setJourneys', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createJourneys({commit}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createJourneys(data);
                await router.push('/journey/' + response.data.serial_number)
                ElMessage({
                    message: 'Journey created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                if (e.response.status == 403) {
                    ElMessage({
                        message: e.response.data.message,
                        type: 'error',
                    })
                } else if (e.response.status == 422) {
                    commit('setErrors', e.response)
                    console.log(e.response.data.errors)
                    ElMessage({
                        message: 'Fix the following errors;<br><br>' + e.response.data.errors[0],
                        type: 'error',
                        dangerouslyUseHTMLString: true,
                    })
                } else {
                    ElMessage({
                        message: 'Error creating Journey!',
                        type: 'error',
                    })
                }
            }
        },
        async editJourney({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                await api.editJourney(data);
                dispatch('getJourneys', {
                    paginate: true
                });
                ElMessage({
                    message: 'Journey updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error updating Journey',
                    type: 'error',
                })
            }
        },
        async deleteJourney({dispatch}, payload) {
            try {
                const {message} = await api.deleteJourney(payload)
                dispatch('getJourneys', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async exportJourneys(context, filters) {
            try {
                const response = await api.exportJourneys(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'journeys ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },


        async getJourneyDetails({commit}, filters) {
            try {
                const response = await api.getJourneyDetails(filters);
                commit('setJourneyDetails', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createJourneyDetails({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createJourneyDetails(data);
                dispatch('getJourneyDetails', {
                    journey_id: response.data.journey_id,
                    paginate: true
                })
                ElMessage({
                    message: 'Journey detail created successfully!',
                    type: 'success',
                })
            } catch (e) {
                console.log(e)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Journey!',
                    type: 'error',
                })
            }
        },
        async editJourneyDetail({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.editJourneyDetail(data);
                dispatch('getJourneyDetails', {
                    journey_id: response.data.journey_id,
                    paginate: true
                });
                ElMessage({
                    message: 'Journey updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error updating Journey',
                    type: 'error',
                })
            }
        },
        async deleteJourneyDetail({dispatch}, payload) {
            try {
                const {message} = await api.deleteJourneyDetail(payload)
                dispatch('getJourneys', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },


        async getJourneyCheckpoints({commit}, filters) {
            try {
                const response = await api.getJourneyCheckpoints(filters);
                commit('setJourneyCheckpoints', response.data);
                commit('setLastCheckpoint', response.last_checkpoint)
                console.log(response, 'response')
            } catch (e) {
                console.log(e)
            }
        },

        async createJourneyCheckpoints({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createJourneyCheckpoints(data);
                dispatch('getJourneyCheckpoints', {
                    journey_detail_id: response.data.journey_detail_id,
                    paginate: true,
                })
                console.log(response)
                ElMessage({
                    message: 'Journey detail created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)

                if (e.response.status == 422) {
                    commit('setErrors', e.response)
                    ElMessage({
                        message: 'Fix the following errors;<br><br>' + e.response.data.errors[0],
                        type: 'error',
                        dangerouslyUseHTMLString: true,
                    })
                } else {
                    ElMessage({
                        message: 'Error creating checkpoint!',
                        type: 'error',
                    })
                }
            }
        },
        async editJourneyCheckpoint({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                await api.editJourneyCheckpoint(data);
                dispatch('getJourneyCheckpoints', {
                    paginate: true,
                    journey_detail_id: data.journey_detail_id
                });
                ElMessage({
                    message: 'Journey updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error updating Journey',
                    type: 'error',
                })
            }
        },
        async deleteJourneyCheckpoint({dispatch}, payload) {
            try {
                const {message} = await api.deleteJourneyCheckpoint(payload)
                dispatch('getJourneyCheckpoints', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },


        async downloadJourneyReport({commit}, data) {
            commit('setLoading', true)
            try {
                const response = await api.downloadJourneyReport(data);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', data.serial_number + ' - ' + Date.now() + '.pdf');
                document.body.appendChild(link);
                link.click();
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },


        async getNonComplianceReasons({commit}, filters) {
            try {
                const response = await api.getNonComplianceReasons(filters);
                commit('setNonComplianceReasons', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getSkipReasons({commit}, filters) {
            try {
                const response = await api.getSkipReasons(filters);
                commit('setSkipReasons', response);
            } catch (e) {
                console.log(e)
            }
        },


        async getJourneyHops({commit}, filters) {
            try {
                const response = await api.getJourneyHops(filters);
                commit('setJourneyHops', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createJourneyHops({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createJourneyHops(data);
                dispatch('getJourneyHops', {
                    id: response.data.company_id,
                    paginate: true,
                })
                console.log(response)
                ElMessage({
                    message: 'Journey detail created successfully!',
                    type: 'success',
                })
            } catch (e) {
                console.log(e)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Journey!',
                    type: 'error',
                })
            }
        },
        async editJourneyHop({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                await api.editJourneyHop(data);
                dispatch('getJourneyHops', {
                    paginate: true,
                    id: data.company_id
                });
                ElMessage({
                    message: 'Journey updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error updating Journey',
                    type: 'error',
                })
            }
        },
        async deleteJourneyHop({dispatch}, payload) {
            try {
                const {message} = await api.deleteJourneyHop(payload)
                dispatch('getJourneyHops', {
                    id: payload.company_id
                })
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadJourneyHops(context, payload) {
            return await api.uploadJourneyHops(payload)
        },
        async exportJourneyHops(context, filters) {
            try {
                const response = await api.exportJourneyHops(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'routes ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },


        async getTruckOpenJourneys({commit}, id) {
            try {
                const response = await api.getTruckOpenJourneys(id);
                commit('setTruckHasOpenJourneys', response);
            } catch (e) {
                console.log(e)
            }
        },

        async closeJourneyDetail({commit}, filters) {
            try {
                commit('hasErrors', false)
                await api.closeJourneyDetail(filters);
                // dispatch('getJourneyDetails', {
                //     journey_id: response.data.journey_id,
                //     paginate: true
                // });
                ElMessage({
                    message: 'Journey detail closed successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error updating Journey',
                    type: 'error',
                })
            }
        },
    }
}
