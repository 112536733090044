import client from "./client";

export default {
    async getTowns(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/towns', filters)));
    },

    async createTowns(data) {
        return client.parseResponse(await client.post('/towns', data))
    },

    async getTown(id) {
        return client.parseResponse(await client.get('/towns/' + id));
    },

    async editTown(data) {
        return client.parseResponse(await client.put('/towns/' + data.id, data))
    },
    async deleteTown(payload) {
        return client.parseResponse(await client.delete('/towns/' + payload.id));
    },

    async getCompanyTowns(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/company-towns', filters)));
    },

    async createCompanyTowns(data) {
        return client.parseResponse(await client.post('/company-towns', data))
    },

    async getCompanyTown(id) {
        return client.parseResponse(await client.get('/company-towns/' + id));
    },

    async editCompanyTown(data) {
        return client.parseResponse(await client.put('/company-towns/' + data.id, data))
    },
    async deleteCompanyTown(payload) {
        return client.parseResponse(await client.delete('/company-towns/' + payload.id));
    },
    async uploadTowns(payload) {
        return client.parseResponse(await client.upload('/import-towns', payload, payload.file))
    },
    async exportTowns(filters) {
        return await client.get('/export-towns', filters, {responseType: 'blob'});
    },
    async uploadCompanyTowns(payload) {
        return client.parseResponse(await client.upload('/import-company-towns', payload, payload.file))
    },
    async exportCompanyTowns(filters) {
        return await client.get('/export-company-towns', filters, {responseType: 'blob'});
    },
}
